/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FunctionComponent, PropsWithChildren, MouseEvent, MouseEventHandler } from 'react';
import classNames from 'clsx';
import { Link as NextJSLink } from '../../../../i18n';
import { LinkProps as NextJSLinkProps } from 'next/link';

/**
 * Accessible link widget following https://www.w3.org/TR/wai-aria-practices-1.1/#link pattern/guidelines
 *
 * A link widget provides an interactive reference to a resource.
 * The target resource _can be either external or local_, i.e., either outside or within the current page or application.
 */
export type LinkProps = NextJSLinkProps &
    PropsWithChildren<{
        /**
         * Whether the link is disabled
         */
        disabled?: boolean;

        /**
         * Extra optional CSS classnames to style the <a> link
         */
        className?: string;

        /**
         * Optional click handler
         */
        onClick?: MouseEventHandler<HTMLAnchorElement>;

        /**
         * Optional title attribute
         */
        title?: string;

        tabIndex?: number;
    }>;

function isRelativeURL(href: string): boolean {
    return href.indexOf('/') === 0 && href.indexOf('//') !== 0;
}

export const Link: FunctionComponent<LinkProps> = ({
    href,
    disabled,
    className,
    children,
    onClick,
    title,
    tabIndex,
}: LinkProps): JSX.Element => {
    function handleClick(ev: MouseEvent<HTMLAnchorElement>): void {
        if (disabled) {
            ev.preventDefault();
        } else if (onClick) {
            onClick(ev);
        }
    }

    const classnames: string = classNames('Link', className, {
        disabled,
    });

    if (href && typeof href === 'string' && !isRelativeURL(href)) {
        return (
            // See https://developers.google.com/web/tools/lighthouse/audits/noopener
            // and https://html.spec.whatwg.org/multipage/links.html#link-type-noreferrer
            <a
                href={href}
                onClick={handleClick}
                className={classnames}
                target="_blank"
                rel="noopener noreferrer"
                title={title}
                tabIndex={tabIndex}
            >
                {children}
            </a>
        );
    } else {
        return (
            <NextJSLink href={href}>
                <a onClick={handleClick} className={classnames} title={title} tabIndex={tabIndex}>
                    {children}
                </a>
            </NextJSLink>
        );
    }
};

Link.displayName = 'Link';
Link.defaultProps = { tabIndex: 0 };
